import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import useFetchAemBrspdContents from "../../../../shared/hooks/useFetchAemBrspdContents";
import ApiUrls from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";

type BannerNotificationInitialState = {
  bannerNotification: any;
  loading: boolean;
  error: any;
};

const initialState: BannerNotificationInitialState = {
  bannerNotification: null,
  loading: false,
  error: null,
};

const host = window?.location?.host;
const file = 'banner-notifications.json';

export const getBannerNotification = createAsyncThunk(
  "get/notification-banner/content",
  async () => {
    try {
      const response = await useFetchAemBrspdContents({
        [ApiUrls.aem_content]: `${file}?appName=${host}`,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const bannerNotificationSlice = createSlice({
  name: "notification-banner/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBannerNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBannerNotification.fulfilled, (state, action) => {
      state.bannerNotification = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getBannerNotification.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "notification-banner",
        action.error?.message,
        action?.type
      );
      state.bannerNotification = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default bannerNotificationSlice.reducer;