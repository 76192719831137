import { useEffect } from "react";
import { setSaveChangeModal } from "../SharedContextStore/slices/saveChangesModal";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../hooks";
const useNavigationBlocker = () => {
  const { communityPreferenceToggle } = useAppSelector(
    (state) => state.communityPreferenceToggle
  );
  const includedClasses = [
    "menuicon",
    "logo",
    "footerleftBottom",
    "footerright",
    "sidebar-navigation",
    "logout-btn",
    "bellnotification",
    "notificationBox",
    "manage-service-tab",
    "lableForgotCurrentPassLink",
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    if (communityPreferenceToggle) {
      const handleNavigationAttempt = (event: Event) => {
        const target = event.target as HTMLElement;

        const isIncluded = includedClasses.some((className) =>
          target.closest(`.${className}`)
        );

        if (isIncluded) {
          // Prevent interaction and trigger modal
          event.preventDefault();
          event.stopPropagation();
          dispatch(setSaveChangeModal(true));
          return;
        }
      };

      document.addEventListener("click", handleNavigationAttempt, true);

      return () => {
        document.removeEventListener("click", handleNavigationAttempt, true);
      };
    }
  }, [communityPreferenceToggle, includedClasses, dispatch]);
};

export default useNavigationBlocker;
