import { reviseOrder } from "../../web/src/SharedContextStore/slices/shopRevieworder";

export default {
  paymentHistoryUrl: "digital/v1/payment/credit/v1/payment-history",
  paymentHistoryUrl_QA_URL:
    "digital/v1/payment/order/credit/v1/payment-history",
  //listPaymentMethodsUrl: "digital/v2/payment/credit/list-payment-methods",
  listPaymentMethods_QA_Url:
    "digital/v1/payment/order/credit/list-payment-methods",
  forgotUserName_QA_URL: 
  "digital/v1/accountmgmt/getAccountDetailsByFilters",
  // addUpdatePaymentMethodUrl: "digital/v2/payment/order/payment-methods",
  //addUpdatePaymentMethodUrl: "digital/v2/payment/credit/payment-methods",
  addUpdatePaymentMethodUrl_QA:
    "digital/v1/payment/order/credit/payment-methods",

  //updatePaymentMethodUrl: "digital/v1/payment/order/updatepayment-methods",
  updatePaymentMethodUrl_QA:
    "digital/v1/payment/order/credit/updatepayment-methods",
  //deletePaymentMethodUrl: "digital/v1/payment/credit/payment-methods",
  deletePaymentMethodUrl_QA: "digital/v1/payment/order/credit/payment-methods",
  paymentHistoryUrlForPA_QA:"digital/v1/payment/order/payment-arrangement/history",
  serviceDetailsUrl: "/digital/v1/accountmgmt",
  accountManagementUrl: "/digital/v1/accountmgmt",
  paymentArrangementUrl_QA: "/digital/v1/payment/order/payment-arrangement/pay",
  accountManagementURLWrapper:
    "/digital-account-management/api/accountmanagementms/v1",
  fetchPastAppointments: `digital/v1/techappointment/user-appointments`,
  upComingAppointmentUrl: `digital/v1/techappointment/user-appointments`,
  upComingAppointmentUrl_QA1: `digital-appointments/api/appointmentsms/createappointment/user-appointments`,
  upComingAppointmentUrl_QA: `digital/v1/techappointment/user-appointments`,
  appointmentDetailsUrl: "digital/v1/techappointment/getAppointmentDetails",
  appointmentDetailsUrlForCTL: "digital/v1/techappointment/availableSlots",
  rescheduleAppointmentUrl: "digital/v1/techappointment/rescheduleAppointment",
  submitRescheduledAppointmentUrl:
    "digital/v1/techappointment/submit-rescheduled-appointment",
  cancelAppointmentUrl:
    "digitalappointments/api/appointmentsms/createappointment/cancelAppointment",
  makePaymentMethodDefaultUrl:
    "digital/v1/payment/order/credit/make-paymentid-default",
  paymentTokenUrl: "digital/v1/payment/order/generateToken",
  paymentGenerateSignatureUrl: "digital/v1/payment/order/generateSignature",
  upcomingPaymentUrl: "digital/v1/payment/credit/upcoming-payments",
  upcomingPayment_QA_Url: "digital/v1/payment/order/credit/upcoming-payments",
  planDetailsUrl: "digital/v1/payment/credit/subscription-plan-details",
  planDetails_QA_Url:
    "digital/v1/payment/order/credit/subscription-plan-details",
  accountIdUrl: "digital/v1/payment/credit/upcoming-payments",
  accountId_QA_Url: "digital/v1/payment/order/credit/upcoming-payments",
  verifyUserNameUrl: "accountmanagement/105247428/verify-user-name",
  verifyPasswordUrl: "accountmanagement/105250492/verify-password",

  viewInvoicePDF_Url: "digital/v1/payment/credit/view-invoice-pdf",
  viewInvoicePDF_Url_QA: "digital/v1/payment/order/credit/view-invoice-pdf",

  passwordResetConfirmation: "digital/v1/accountmgmt/send-reset-confirmation",
  iAmTokenUrl: "digital/v1/accountmgmt/get-iamt",
  updateAccount: "digital/v1/accountmgmt/update-customer-profile",
  forgotPassGenerateOTPUrl: "digital/v1/accountmgmt/forgot-password",
  forgotUsernameUrl: "digital/v1/accountmgmt/forgotusername",
  validateOTP_Url: "digital/v1/accountmgmt/validate-otp",
  /* digitalservices_dev_serviceDetailsUrl:
    "digital/v1/accountmgmt/service-details-user", */
  digitalservices_dev_serviceDetailsUrl:
    "digital/v1/accountmgmt/get-service-details",
  userInfoByAccIdUrl: "digital/v1/accountmgmt/getAccountInformation",
  registerUserAIM_url: "digital/v1/accountmgmt/register-user",
  invoiceDetailsUrl: "digital/v1/payment/credit/invoice-pdf-upcoming-payment",
  invoiceDetails_QA_Url:
    "digital/v1/payment/order/credit/invoice-pdf-upcoming-payment",
  msgToTech: "digital/v1/payment/message",
  dispatchMsgToTech: "digital/v1/techappointment/message-tech",
  resendOTPUrl: "digital/v1/accountmgmt/resend-otp",
  brightspeedGetTokenAPI: "digital/v1/digitalId",
  decryptionQstr_Url: "digital/v1/accountmgmt/unency",
  encryptionQstr_Url: "digital/v1/accountmgmt/ency",
  addressQualificationURL: "digital/v1/addressqualification",
  markPaymentDefault: "digital/v1/payment/order/credit/make-paymentid-default",
  unifiedLoginWithMobileNumberTN_lookup_Url:
    "digital/v1/addressqualification/tn-lookup",
  unifiedLoginWithEmailAddress_lookup_Url:
    "digital/v1/accountmgmt/lookup-accounts",
  case: "digital/v1/accountmgmt/create-acp-case",
  buryCableMsgUrl: "digital/v1/accountmgmt/bswinfo",
  captchaTokenValidateURL: "digital/v1/porting/recaptcha",
  repairAppointmentURL: "digital/v1/techappointment/repairAppointment",
  pauseService: "digital/v1/payment/order/suspend-order",
  dwLoggingUrl: "digital/v1/porting/dw-publish",
  purlPaymentUrl: "digital/v1/payment/order/paynow",
  /*  paypalUrl: "digital/v1/payment/order/credit", */
  ctlCustomerUrl:
    "v1/brim/customerprofile?$filter=BusinessPartner eq '478820116'&$expand=Customer360ToCustomerHeaderNav,Customer360ToCustomerHeaderNav/CustomerHeaderToCustomerNav,Customer360ToCustomerHeaderNav/CustomerHeaderToAddressNav,Customer360ToCustomerHeaderNav/CustomerHeaderToTelephoneNav,Customer360ToSubscriptionNav,Customer360ToSubscriptionNav/SubcriptionsToHeaderNav,Customer360ToSubscriptionNav/SubcriptionsToItemsNav/ItemsToItemNewNav,Customer360ToSubscriptionNav/SubcriptionsToItemsNav/ItemsToConfigNav,Customer360ToSubscriptionNav/SubcriptionsToItemsNav/ItemsToDatesNav,Customer360ToSubscriptionNav/SubcriptionsToItemsNav/ItemsToPridocNav,Customer360ToSubscriptionNav/SubcriptionsToItemsNav/ItemsToTechresNav,Customer360ToSubscriptionNav/SubcriptionsToItemsNav/ItemsToIstitemNav,Customer360ToSubscriptionNav/SubcriptionsToItemsNav/ItemsToPartnersNav&$format=json&sap-client=110", //Poc work Added
  ctlPricingUrl:
    "v1/brim/pricing?$filter=PricePlan eq 'MM_Recurring_FeatureCode_PricePlan_V3'&$format=json&sap-client=110", // Poc work Added
  cardVerificationUrl: "digital/v1/payment/order/paymentAuthId", //Ankit for add  paymnet method
  paypalUrl: "digital/v1/payment/order/credit",
  // amsTokenUrl: "v1/authentication",
  amsTokenUrl:
    "oauth/client_credential/accesstoken?grant_type=client_credentials",
  // amsLookUpURL: "ams/v1/buyflow/restservices/adq-api/v1",
  amsLookUpURL: "ams2-digital/restservices/adq-api/v1",
  // amsLookUpURL: "ams-digital/restservices/adq-api/v1/lookup?address=",
  notificationStatusUrl: "digital/v1/accountmgmt/notification",
  getNotificationWithBan: "digital/v1/accountmgmt/ban-notification",
  quickPayURL: "digital/v1/payment/order/quickBillPay", //already v1
  axonTokenURL: "oauth/client_credential",
  axonURl: "digital/v1/accountmgmt/modemDetails",
  getAzureInfoUrl: "digital/v1/accountmgmt/getazinfo",
  zuoraProductDetailsvoice :"digital/v1/payment/order/credit/zuoraProductDetails",
  paymetArrangemetUserValidateURL:
    "digital/v1/accountmgmt/getAccountDetailsByFilters",
  getAccountDetailsByBANNZIP: "digital/v1/accountmgmt/getAccountDetailsByBan",
  managePlandetailsCTL: "digital/v1/payment/order/credit/subscription-acm",
  getServiceDetails_with_ban: "digital/v1/accountmgmt/get-service-details",
  addAutoPaymentMethodUrl_QA: "digital/v1/payment/order/addNewPaymentMethod",
  purlPaymentAuthId: "digital/v1/purl/paymentAuthId",
  enrollAutoPay: "digital/v1/payment/order/enroll",
  updateCustomerId_Url: "digital/v1/accountmgmt/update-customer-Id",
  remove_autopay: "digital/v1/payment/order/remove-autopay",
  update_autopay: "digital/v1/payment/order/update-autopay-plan",
  forgot_account_number: "digital/v1/accountmgmt/forgot-account-id",
  non_auth_paymentArrangements:
    "digital/v1/payment/order/payment-arrangement/fetchPlans",
  nutritionlabel: "digital/v1/product/nutritionLabel",
  checkEmail: "digital/v1/accountmgmt/check-email",
  add_account_send_Otp: "digital/v1/accountmgmt/send-account-otp",
  validate_user_name:"digital/v1/accountmgmt/validate-username",
  registered_user_notify:"digital/v1/accountmgmt/registered-user-notify",
  getV2InternetProduct: "digital/v1/product/internet",
  getVoiceProductUrl:"digital/v1/product/voice",
  createCart:"digital/v1/cartutility/createCart",
  updateCart: "digital/v1/cartutility/updateCart",
  addonsProducts:"digital/v1/product/addOns",
  validatePortingTelephone: "digital/v1/porting/telephoneNumber",
  voicePortingData: "digital/v1/porting/prevalidate",
  changeOrder:"digital/v1/order/submit",
  reviseOrder:"digital/v1/payment/order/submit-revision-order",
  updateCommPreferenc:"digital/v1/accountmgmt/update-preferenceCenter",
   /** ---- AEM URLs */
  aem_content: "content/dam/consumer-app/account-management/entry",
  payment_arrangment_send_otp:"digital/v1/accountmgmt/send-pa-otp",
};
