const QF_Type = [
  "QUANTUM FIBER",
  "QF",
  "QF-II",
  "QUANTUM FIBER II",
];
const CTL_Type = [
  "CENTURY LINK",
  "CTL",
  "CTL QUALIFIED",
  "CTL_QUALIFIED",
  "COPPER QUALIFIED"
];

const initGTM = () => {
  window.dataLayer = window.dataLayer || [];
};

const onPageLoadGTM = (
  pageName,
  step,
  sessionId,
  billingAccountNumber,
  companyName
) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";;
  window.dataLayer.push({
    page: {
      pageType: `${pageType}_Account_Management`,
      pageName: pageName || "",
    },
    flow: {
      name: "BRSPD_Fiber_EC_Flow",
      step: step || undefined,
    },
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
    channel: "digital",
  });
};

const backButtonClickedGTM = (
  name,
  step,
  sessionId,
  billingAccountNumber,
  companyName
) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  window.dataLayer.push({
    event: "back",
    pageType: `${pageType}_Account_Management`,
    flow: {
      name: name,
      step: step,
    },
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
    channel: "digital",
  });
};

const errorConditionGTM = (
  name,
  step,
  errorCode,
  errorName,
  sessionId,
  billingAccountNumber,
  companyName
) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  window.dataLayer.push({
    event: "error",
    pageType: `${pageType}_Account_Management`,
    flow: {
      name: name,
      step: step,
    },
    errorCode: errorCode,
    errorName: errorName,
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
    channel: "digital",
  });
};

const loginGTM = (eventName, username, companyName) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  window.dataLayer.push({
    event: eventName,
    pageType: `${pageType}_Account_Management`,
    //event: "digital_login_acct_mgmt",
    user: username,
    channel: "digital",
    sessionId: localStorage?.getItem("sessionId")
  });
};

const shopConfirmOrderGTM = (orderNumber,orderDate,VasAdded,internetchange,voiceAddedOrRemoved,InternetPlanName,sessionId) => {

  window.dataLayer.push({
    event: "change_Order_confirm_button",
    channel: "digital",
    orderNumber: orderNumber,
    orderdate: orderDate,
    total_home_tech_support:  VasAdded === "add" ? true : false,
    internetchange: internetchange,
    voiceAdded: voiceAddedOrRemoved === "add" ? true : false,
    voiceRemoved: voiceAddedOrRemoved === "delete" ? true : false,
    newInternetPlanName: InternetPlanName,
    sessionId: sessionId
  });
}

const forgotPasswordGTM = (companyName, username) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  window.dataLayer.push({
    event: "digital_forgot_password_acct_mgmt",
    pageType: `${pageType}_Account_Management`,
    channel: "digital",
    user: username,
    sessionId: localStorage?.getItem("sessionId")
  });
};

const homeGTM = (companyName, accountType, accountTenure, accountStatus, autoPay, paperlessBilling, currentAmountDue,
  billDueDate,lastInvoiceAmount, lastBillDate, internet_plan_speed, stateCode, wireCenter, voice, offers1q) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  console.log("<=== New GTM tag trigged ===>");
  window.dataLayer.push({
    event: "digital_home_acct_mgmt",
    pageType: `${pageType}_Account_Management`,
    channel: "digital",
    sessionId: localStorage?.getItem("sessionId"),
    accountType: accountType,
    accountTenure: accountTenure,
    accountStatus: accountStatus,
    autoPay: autoPay,
    paperlessBilling: paperlessBilling,
    currentAmountDue: currentAmountDue,
    billDueDate: billDueDate,
    lastInvoiceAmount: lastInvoiceAmount,
    lastBillDate: lastBillDate,
    internet_plan_speed: internet_plan_speed,
    state_code: stateCode,
    wire_center: wireCenter,
    voice: voice,
    offers1q
  });
};


const nonAuthenticQBPGTM = (
  {companyName, accountType, accountTenure, accountStatus, autoPay, paperlessBilling, currentAmountDue,
  billDueDate,lastInvoiceAmount, lastBillDate, internet_plan_speed, stateCode, wireCenter, voice}
) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";

  window.dataLayer.push({
    event: "digital_nonauthentic_quickbillpay_acct_mgmt",
    pageType: `${pageType}_Account_Management`,
    channel: "digital",
    sessionId: localStorage?.getItem("sessionId"),
    accountType,
    accountTenure,
    accountStatus,
    autoPay,
    paperlessBilling,
    currentAmountDue,
    billDueDate,
    lastInvoiceAmount,
    lastBillDate,
    internet_plan_speed,
    stateCode,
    wireCenter,
    voice
  });
};

const nonAuthenticPAGTM = (
  {companyName, accountType, accountTenure, accountStatus, autoPay, paperlessBilling, currentAmountDue,
  billDueDate,lastInvoiceAmount, lastBillDate, internet_plan_speed, stateCode, wireCenter, voice}
) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";

  window.dataLayer.push({
    event: "digital_nonauthentic_paymentarrangements_acct_mgmt",
    pageType: `${pageType}_Account_Management`,
    channel: "digital",
    sessionId: localStorage?.getItem("sessionId"),
    accountType,
    accountTenure,
    accountStatus,
    autoPay,
    paperlessBilling,
    currentAmountDue,
    billDueDate,
    lastInvoiceAmount,
    lastBillDate,
    internet_plan_speed,
    stateCode,
    wireCenter,
    voice
  });
};

// const accountSettingGTM = () => {
//   window.dataLayer.push({
//     event: "digital_account-setting_acct_mgmt",
//     channel: "digital",
//   });
// };

// const viewPaymentsGTM = () => {
//   window.dataLayer.push({
//     event: "digital_viewPayments_acct_mgmt",
//     channel: "digital",
//   });
// };

// const paymentMethodsGTM = () => {
//   window.dataLayer.push({
//     event: "digital_paymentMethods_mgmt",
//     channel: "digital",
//   });
// };

// const appointmentGTM = () => {
//   window.dataLayer.push({
//     event: "digital_appointment_mgmt",
//     channel: "digital",
//   });
// };

// const helpandsupportGTM = () => {
//   window.dataLayer.push({
//     event: "digital_help-and-support_mgmt",
//     channel: "digital",
//   });
// };

const sideMenuClickGTM = (eventName, companyName, username) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  window.dataLayer.push({
    event: eventName,
    pageType: `${pageType}_Account_Management`,
    channel: "digital",
    user: username,
    sessionId: localStorage?.getItem("sessionId")
  });
};

const forgotUserNameGTM = () => {
  window.dataLayer.push({
    event: "digital_forgot_username_acct_mgmt",
    channel: "digital",
    sessionId: localStorage?.getItem("sessionId")
  });
};

const signupGTM = () => {
  window.dataLayer.push({
    event: "digital_create_account_acct_mgmt",
    channel: "digital",
    sessionId: localStorage?.getItem("sessionId")
  });
};

const logoutGTM = (companyName, username) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  window.dataLayer.push({
    event: "digital_logout_acct_mgmt",
    pageType: `${pageType}_Account_Management`,
    channel: "digital",
    user: username,
    sessionId: localStorage?.getItem("sessionId")
  });
};

const addEneventGTM = (
  eventName,
  sessionId,
  billingAccountNumber,
  companyName
) => {
  const pageType = CTL_Type?.includes(
    companyName?.toString()?.trim()?.toUpperCase()
  ) ? "CTL" 
    : QF_Type?.includes(
      companyName?.toString()?.trim()?.toUpperCase()
    ) ? "QF"
    : "BRSPD";
  window.dataLayer.push({
    event: eventName || undefined,
    pageType: `${pageType}_Account_Management`,
    channel: "digital",
    sessionId: sessionId,
    billingAccountNumber: billingAccountNumber,
  });
};

const uniLogineventGTM = (loginMethod, loginValue) => {
  window.dataLayer.push({
    event: "uni-login-account-management",
    pageType: "BRSPD_Fiber_Account_Management",
    channel: "digital",
    loginMethod: loginMethod,
    loginValue: loginValue,
    sessionId: localStorage?.getItem("sessionId")
  });
};

export {
  onPageLoadGTM,
  backButtonClickedGTM,
  errorConditionGTM,
  initGTM,
  loginGTM,
  forgotPasswordGTM,
  forgotUserNameGTM,
  homeGTM,
  signupGTM,
  addEneventGTM,
  nonAuthenticQBPGTM,
  nonAuthenticPAGTM,
  shopConfirmOrderGTM,
  //paymentMethodsGTM,
  //helpandsupportGTM,
  //appointmentGTM,
  //accountSettingGTM,
  //viewPaymentsGTM,
  sideMenuClickGTM,
  uniLogineventGTM,
  logoutGTM,
};
