import {
  getNotification,
  updateNotification,
} from "../../../shared/hooks/useFetchNotifications";
import ApiUrls from "../../../shared/utils/ApiUrls";
import { format } from "date-fns";
import { useNavigate } from 'react-router-dom';

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 300);
  };
};



export const formatDateWithOffset  = (day) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0-based index (0 = Jan, 1 = Feb, ...)
  const currentYear = currentDate.getFullYear();

  let newDay = Number(day) + 2; // Add 2 days to the given day
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Get the last valid day of the month

  let newMonth = currentMonth;
  let newYear = currentYear;

  // If newDay exceeds the month's last day, move to next month
  if (newDay > lastDayOfMonth) {
    newDay -= lastDayOfMonth; // Shift extra days to next month
    newMonth += 1; // Move to next month

    // If the new month exceeds December (11), shift to next year
    if (newMonth > 11) {
      newMonth = 0; // Reset to January
      newYear += 1; // Move to next year
    }
  }

  const formattedMonth = String(newMonth + 1).padStart(2, "0"); // Ensure two-digit month
  const formattedDay = String(newDay).padStart(2, "0"); // Ensure two-digit day
  const val= `${formattedMonth}/${formattedDay}/${newYear}`
  return val;
};

export const replaceJSX = (text, find1, replace1, find2, replace2,key="") => {
const output = text.split(find1).flatMap((item) => [item, replace1]);
output?.pop();
if (find2 && replace2 && output[output.length - 1]) {
  const output2 = output[output.length - 1]
    .split(find2)
    .flatMap((item) => [item, replace2]);
  output2?.pop();
  output?.pop();
  return [...output, key==="CCBanner" ? "." : '',...output2];
}
return output;
};

export const isOnlyForBAU = () => {
  const env = getAmHost();
  const ENV_needToEnable = [
    "PROD"
  ];
  return env && ENV_needToEnable?.includes(env);
}

export const getAmHost = () => {
  const url = window.location.host;
  switch (url) {
    case "myaccountnextdev2.brightspeed.com":
      return "NEXT_DEV2";
    case "myaccountnextqa.brightspeed.com":
      return "NEXT_QA";
    case "myaccountnextqa2.brightspeed.com":
      return "NEXT_QA2";
    case "myaccountdev2.brightspeed.com":
      return "BAU_DEV2";
    case "myaccountqa1.brightspeed.com":
      return "BAU_QA1";
    case "myaccountqa2.brightspeed.com":
      return "BAU_QA2";
    case "myaccountuat.brightspeed.com":
      return "UAT";
    case "myaccountuat2.brightspeed.com":
      return "UAT2";
    case "myaccount.brightspeed.com":
      return "PROD";
    // case "localhost:3000":
    //    return "PROD";
  }
};

export const encryptText = (text, privateKey) => {
  try {
    if (window?.pidCryptUtil && window?.pidCrypt) {
      var key = window?.pidCryptUtil.decodeBase64(privateKey);
      var rsa = new window.pidCrypt.RSA();
      //ASN1 parsing
      var asn = window?.pidCrypt.ASN1.decode(
        window?.pidCryptUtil.toByteArray(key)
      );
      var tree = asn.toHexTree();
      //setting the public key for encryption with retrieved ASN.1 tree
      rsa.setPublicKeyFromASN(tree);
      // Base64 encode and encrypt the string
      var crypted = rsa.encrypt(text);
      return window?.pidCryptUtil.encodeBase64(
        window?.pidCryptUtil.convertFromHex(crypted)
      );
    }
  } catch (error) {
    console.info(error);

    return error;
  }
};

export const onEnterKeyPress = (event, callBackFun) => {
  if (event.key === "Enter") {
    event?.preventDefault();
    if (callBackFun && typeof callBackFun === "function") {
      callBackFun(event);
    }
  }
};

export const toggleOneTrust = () => {
  if (window?.OneTrust) {
    window?.OneTrust?.ToggleInfoDisplay();
  }
};
export const calculateRemainingBalance = (paymentHistoryForPA) => {
 const remainingBalance = paymentHistoryForPA?.paymentHistory?.treatment?.[0]?.installment?.reduce(
    (remainingBalance, item) => {
      if (item.status !== "Paid") {
        return remainingBalance + item.amount;
      }
      return remainingBalance;
    },
    0
  );

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(remainingBalance);
}
export const formatDueDate = (dueDate) => {
  if (!dueDate) return '';

  const formattedDate = new Date(dueDate?.replace(/-/g, "/"));
  const month = formattedDate?.getMonth() + 1; // 0-indexed, add 1
  const day = formattedDate?.getDate(); // Automatically single digit if < 10
  const year = formattedDate?.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatSuspendEndDate = (dueDate,isdisplay=true) => {
  if (!dueDate) return '';

  const formattedDate = new Date(dueDate?.replace(/-/g, "/"));
  const TempMonth = formattedDate?.getMonth() + 1; // 0-indexed, add 1
  const tempday = formattedDate?.getDate(); // Automatically single digit if < 10
  const year = formattedDate?.getFullYear();
  const day = tempday < 10 ? `0${tempday}` : `${tempday}`;
  const month = TempMonth < 10 ? `0${TempMonth}` : `${TempMonth}`;

  return (isdisplay)? `${month}/${day}/${year}`:`${year}-${month}-${day}`;
};

export const addOneMonth = (dueDate) => {
  if (!dueDate) return '';
  const tempFormatDate = formatSuspendEndDate(dueDate,false);
  // Split input into year, month, day
  const [year, month, day] = tempFormatDate.split('-').map((val) => parseInt(val, 10));
  const parsedDate = new Date(year, month - 1, day);

  // Store the original day to check if it overflows
  const originalDay = parsedDate.getDate();

  // Add one month to the current date
  parsedDate.setMonth(parsedDate.getMonth() + 1);

  // Check if the new month has fewer days than the original month
  if (parsedDate.getDate() < originalDay) {
    // Set to the last valid day of the new month
    parsedDate.setDate(0); 
  }

  // Get the updated year, month, and day
  const updatedYear = parsedDate.getFullYear();
  const updatedMonth = parsedDate.getMonth() + 1;  // Month is 0-indexed
  const updatedDay = parsedDate.getDate();

  // Format to ensure two digits for the month and day
  const formattedMonth = updatedMonth < 10 ? `0${updatedMonth}` : `${updatedMonth}`;
  const formattedDay = updatedDay < 10 ? `0${updatedDay}` : `${updatedDay}`;

  // Return in the format YYYY-MM-DD
  return `${updatedYear}-${formattedMonth}-${formattedDay}`;
};




export const phoneNumberValidation = (e) => {
  if (e.keyCode >= 48 && e.keyCode <= 57) {
    e.target.value = e?.target?.value
      ?.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3")
      .substr(0, 13);
  }else if(e.keyCode === 9){
    return true;
  }else {
    if (e.keyCode == 8 || e.keyCode == 46) {
      return false;
    } else {
      e.preventDefault();
      return false;
    }
  }
};

export const phoneNumberValidationMask = (e) => {
  if (!/[0-9 ]/.test(e?.key)) {
    e?.preventDefault();
    return false;
  }
  if (e?.target?.value?.length < 14) {
    e.target.value = e?.target?.value
    ?.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3")
    .substr(0, 13);
  }else if (e?.target?.value?.length === 14){
    return true;
  }else {
    e?.preventDefault();
    return false;
  }
};

export const creditCardExpiryAutoFormat = (e) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
  if (e.target.value.length < 7) {
    e.target.value = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // To handle 3/ > 03/
      )
      .replace(
        /^(0[1-9]{1}|1[0-2]{1})$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1]{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(\d)\/(\d\d)$/g,
        "0$1/$2" // To handle 1/11 > 01/11
      )
      .replace(
        /^(0?[1-9]{1}|1[0-2]{1})([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^(([0]{1,})\/|[0]{1,})$/g,
        "0" // To handle 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]{0,}$/g,
        "" // To allow only numbers and /
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 /
      );
    return true;
  }else if(e.target.value.length===7){
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const getCaptchaToken = async (action = "login") => {
  return new Promise((resolve, reject) => {
    window?.grecaptcha?.enterprise.ready(async () => {
      try {
        const token = await window?.grecaptcha?.enterprise?.execute(
          process.env.REACT_APP_RECAPTCHA_KEY,
          {
            action: action,
          }
        );
        if (token) {
          resolve(token);
        } else {
          reject(Error("something went wrong while genererate captcha token"));
        }
      } catch (error) {
        return reject(error);
      }
    });
  });
};

export const repairAppointmentSortedData = (e) => {
  return e.reduce((a, b) => (a.date > b.date ? a : b));
};

export const getFormattedDate = (value) => {
  let date = new Date(value);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const setNotificationStatusTime = (value, id, type) => {
  let data = value?.notifications?.filter(
    (item) => item.notificationId === id + type
  );
  return data?.length && data[0]?.createdDate;
};
export const getServiceAddressId = (userAddress) => {
  let tempAddr = "";
  if (userAddress) {
    let { addressId } = userAddress?.find((item) => {
      if (item?.addressType && item?.addressType === "ServiceAddress") {
        return item;
      }
    });
    tempAddr = addressId;
  }
  return tempAddr;
};

export const getServiceAddressFromUser = (user) => {
  return user?.addresses?.find(
    (addr) => addr?.addressType === "ServiceAddress"
  );
};

/* Speed pay encryption */
export const encryptNativeSpeedPay = async (text, publicKey) => {
  const encoder = new TextEncoder();
  try {
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(publicKey);

    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);
    const cryptoPublicKey = await window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      { name: "RSA-OAEP", hash: "SHA-256" },
      true,
      ["encrypt"]
    );
    const crypted = await window.crypto.subtle.encrypt(
      cryptoPublicKey.algorithm,
      cryptoPublicKey,
      encoder.encode(text)
    );
    return window.btoa(
      String.fromCharCode.apply(null, new Uint8Array(crypted))
    );
  } catch (error) {
    console.error("Encry", error);
    return await Promise.resolve(text);
  }
};

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export const getDateAndTimeForCTLRescheduleAppoitmentAPI = (date, time) => {
  if (!date || !time) {
    return null;
  }
  return format(new Date(`${date} ${time}`), "yyyy-MM-dd'T'HH:mm:ss");
};

export const getCardNumberWithMasking = (creditCardNumber) => {
  return (
    (creditCardNumber &&
      creditCardNumber?.length > 4 &&
      "****" +
        creditCardNumber?.toString()?.substr(creditCardNumber?.length - 4)) ||
    creditCardNumber
  );
};

export const getBanWithMasking = (ban) => {
  return (
    (ban &&
      ban?.length > 4 &&
      "****" + ban?.toString()?.substr(ban?.length - 4)) ||
    ban
  );
};

export const chatClick = () => {
  if (typeof window.launchChat === "function") {
    window?.launchChat();
  }
};


export function getFormatedStringFromDays(numberOfDays) {
  if(Number.isInteger(numberOfDays)){
    let years = Math.floor(numberOfDays / 365);
    let months = Math.floor(numberOfDays % 365 / 30);
    let days = Math.floor(numberOfDays % 365 % 30);

    let yearsDisplay = years > 0 ? years + (years == 1 ? " year, " : " years, ") : "";
    let monthsDisplay = months > 0 ? months + (months == 1 ? " month, " : " months, ") : "";
    let daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";
    return yearsDisplay + monthsDisplay + daysDisplay; 
  } else {
    return 'not a number';
  }
}

export function isNegative (value) {
  // true if negative string
  if (typeof value === 'string') {
    return value.indexOf('-') > -1 
  }

  if (typeof value === 'number') {
    return Math.sign(value) === -1 
  }
  return;
}

export function valueExtract (value) {
  if (!value) return {};

  if (typeof value === 'number') {
    if(isNegative(value)) {
      return {
        value: Math.abs(value),
        sign: '-',
      }
    }
    return {
      value,
      sign: '',
    }
  }
  if (typeof value === 'string') {
    if(isNegative(value)) {
      return {
        value: value?.replace(/[-$]/g, ''),
        sign: '-',
      }
    }
    return {
      value: value?.replace(/[$]/g, ''),
      sign: '',
    }
  }

  return { // if input is not a String or Number
    value: '0.00',
    sign: ''
  }
}

export function fixValToDecimal (value) {
  if(isNaN(value)) return '0.00'
  return parseFloat(value)?.toFixed(2)
}

export function renderCurrency (inputValue, inputCurrency = '$') {
  if(!inputValue) return '';

  const {value, sign} = valueExtract(inputValue)
  const floatValue = fixValToDecimal(value)
  return `${sign}${inputCurrency}${floatValue}` 
}

export function useHandleShopExpApiError() {
  const navigate = useNavigate();
  return () => {
    const errorPage = '/error';
    navigate(errorPage);
  };
}