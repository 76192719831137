import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToggleState {
  communityPreferenceToggle: boolean;
  saveChangeModal: boolean;
}

const initialState: ToggleState = {
  communityPreferenceToggle: false,
  saveChangeModal: false,
};

const toggleSlice = createSlice({
  name: 'toggle',
  initialState,
  reducers: {
    setCommunityPreferenceToggle: (state, action: PayloadAction<boolean>) => {
      state.communityPreferenceToggle = action.payload;
    },
    setSaveChangeModal: (state, action: PayloadAction<boolean>) => {
      state.saveChangeModal = action.payload;
    },
  },
});

export const { setCommunityPreferenceToggle, setSaveChangeModal } = toggleSlice.actions;
export default toggleSlice.reducer;
