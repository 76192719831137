import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {updateCommPrefInfo} from "../../../../shared/hooks/useFetchAccountInfo";
import store from "../store";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { getServiceAddressId } from "../../Utils/utils";

type commPrefInitialStateType = {
  
  /* commPrefData: any;
  commPrefLoading: boolean;
  commPrefError: any; */
  showEditform: boolean;
};
const initialState: commPrefInitialStateType = {
  /* commPrefData: null,
  commPrefLoading: false,
  commPrefError: null, */
  showEditform: false
}

/* export const updateCommPref :any = createAsyncThunk(
  "commPreferance/updatePref",
  async (preferenceData: any) => {
    try {
      const reduxStore = store.getState();
      const userDetails = reduxStore?.user?.data;
      const { sessionId } = reduxStore?.user;
      const serviceAddrId = getServiceAddressId(userDetails?.addresses);
      const headers = reduxStore?.brightspeedToken?.headers;
      const body ={
        preferenceDetails: preferenceData,
        customerDetails: {
          channel: process.env.REACT_APP_PLAN_CHANNEL_ID || "web-acm",
          primaryPhone: userDetails?.contactNumber,
          secondaryPhone: userDetails?.alternatePhoneNumber,
          addressId: serviceAddrId,
          sessionID: sessionId,
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          email:userDetails?.email,
          billingAccountNumber: userDetails?.billingAccountNumber
        }
      };
      const response = await updateCommPrefInfo(
        {
          "":[API_URLS?.updateCommPreferenc],
        },
        body,
        headers
      );
      return response; 
    } catch (error) {
      throw error;
    }
  }
); */

const commPrefSlice = createSlice({
  name:"commPreferance",
  initialState,
  reducers: {
    setShowEditform: (state, action) => {
      state.showEditform = action.payload;
    },
  },
  extraReducers: (builder) => {
         /* builder.addCase(updateCommPref.pending,(state)=>{
          state.commPrefLoading=true;
          
        });
        builder.addCase(updateCommPref.fulfilled,(state, action)=>{
          state.commPrefData = action.payload;
          state.commPrefLoading= false;
          state.commPrefError= null;
        });
        builder.addCase(updateCommPref.rejected,(state, action)=>{
          
          state.commPrefLoading= false;
          state.commPrefError= action.error;
        }); */
  }
});

export const {setShowEditform} = commPrefSlice.actions;

export default commPrefSlice.reducer;