import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API_URLS from "../../../../shared/utils/ApiUrls";
import {
  getAxonModemDetail,
  getAxonToken,
} from "../../../../shared/hooks/useFetchNetworkSetting";
import store from "../store";

type networkSettingInitialStateType = {
  networkTokenAPI_Details: any;
  networkTokenAPI_loading: boolean;
  networkTokenAPI_error: any;
  modem_Details: any;
  modem_Details_loading: boolean;
  modem_Details_error: any;
  modem_Details_called: boolean;
  modem_with_pass_Details: any;
  modem_with_pass_loading: boolean;
  modem_with_pass_error: any;
  isModemReStarted: boolean;
};
const initialState: networkSettingInitialStateType = {
  networkTokenAPI_Details: null,
  networkTokenAPI_loading: false,
  networkTokenAPI_error: null,
  modem_Details: null,
  modem_Details_loading: false,
  modem_Details_called: false,
  modem_Details_error: null,
  modem_with_pass_Details: null,
  modem_with_pass_loading: false,
  modem_with_pass_error: null,
  isModemReStarted: false,
};

export const getNetworkAPIToken = createAsyncThunk(
  "network-setting/get-token",
  async () => {
    try {
      const headers = {
        Authorization: `Basic ${window.btoa(
          `${process.env.REACT_APP_AXON_USERNAME}:${process.env.REACT_APP_AXON_PASSWORD}`
        )}`,
      };
      const response = await getAxonToken(
        {
          [API_URLS?.axonTokenURL]: `accesstoken?grant_type=client_credentials`,
        },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getModemDetail = createAsyncThunk(
  "network-setting/get-modem",
  async () => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const ban = reduxStore?.user?.data?.billingAccountNumber;
      const serviceDetails = reduxStore?.serviceDetails?.serviceDetails;
      const response = await getAxonModemDetail(
        {
          "": [API_URLS?.axonURl],
        },
        {
          action: "ACS",
          botid: 203,
          sender: ban || "",
          tn: serviceDetails?.serviceIDForAxonAPI,
          method: "getModem",
          search: "tn",
        },
        headers
      );
      if (response?.data?.response?.data?.ACS?.ConnectionStatusError || 
        response?.data?.response?.data?.ACS?.message?.includes("No modems found") ) {
        response.data.response.data.ACS.isModemOffline = true;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const modemWithPass = createAsyncThunk(
  "network-setting/modem-with-pass",
  async (isPasswordTurnOff?: boolean) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const ban = reduxStore?.user?.data?.billingAccountNumber;
      const serviceDetails = reduxStore?.serviceDetails?.serviceDetails;
      const modemInfo = reduxStore?.networkSetting?.modem_Details?.response?.data?.ACS?.getModem?.length > 0 && reduxStore?.networkSetting?.modem_Details?.response?.data?.ACS?.getModem[0];
      const body: any = {
        action: "ACS",
        botid: 203,
        sender: ban || "",
        tn: serviceDetails?.serviceIDForAxonAPI,
        oui: modemInfo?.OUI || "",
        sn: modemInfo?.SerialNumber || "",
        uniqueID: modemInfo?.uniqueID || "",
        productClass: modemInfo?.ProductClass || "",
        method: "getModemWireless",
      };
      if(isPasswordTurnOff) {
        body['encryptionType'] = "None";
      }
      const response = await getAxonModemDetail(
        {
          "": [API_URLS?.axonURl],
        },
        body,
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const networkSettingSlice = createSlice({
  name: "network/setting",
  initialState,
  reducers: {
    startNetworkLoading: (state) => {
      state.modem_Details_loading = true;
    },
    stopNetworkLoading: (state) => {
      state.modem_Details_loading = false;
    },
    setModemReStarted: (state) => {
      state.isModemReStarted = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNetworkAPIToken.pending, (state) => {
      state.networkTokenAPI_loading = true;
    });
    builder.addCase(getNetworkAPIToken.fulfilled, (state, action) => {
      state.networkTokenAPI_Details = action.payload;
      state.networkTokenAPI_loading = false;
      state.networkTokenAPI_error = null;
    });
    builder.addCase(getNetworkAPIToken.rejected, (state, action) => {
      state.networkTokenAPI_Details = null;
      state.networkTokenAPI_loading = false;
      state.networkTokenAPI_error = action.error;
    });

    builder.addCase(getModemDetail.pending, (state) => {
      state.modem_Details_loading = true;
    });
    builder.addCase(getModemDetail.fulfilled, (state, action) => {
      state.modem_Details = action.payload;
      state.modem_Details_loading = false;
      state.modem_Details_error = null;
      state.modem_Details_called = true;
    });
    builder.addCase(getModemDetail.rejected, (state, action) => {
      state.modem_Details = null;
      state.modem_Details_loading = false;
      state.modem_Details_error = action.error;
      state.modem_Details_called = true;
    });

    builder.addCase(modemWithPass.pending, (state) => {
      state.modem_with_pass_loading = true;
    });
    builder.addCase(modemWithPass.fulfilled, (state, action) => {
      state.modem_with_pass_Details = action.payload;
      state.modem_with_pass_loading = false;
      state.modem_with_pass_error = null;
    });
    builder.addCase(modemWithPass.rejected, (state, action) => {
      state.modem_with_pass_Details = null;
      state.modem_with_pass_loading = false;
      state.modem_with_pass_error = action.error;
    });
  },
});

export const { startNetworkLoading, stopNetworkLoading, setModemReStarted } =
  networkSettingSlice.actions;
export default networkSettingSlice.reducer;
